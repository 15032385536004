import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { BehaviorSubject, map, Subject, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  BranchContent,
  BranchDataList,
  BranchFilter,
  BranchResponse,
  Pagination,
} from '../models';
import {
  MerchantData,
  MerchantResponse,
} from '../models/merchant-detail.model';

@Injectable({
  providedIn: 'root',
})
export class DetailsDataService {
  private _pagination: Subject<Pagination | null> =
    new Subject<Pagination | null>();

  get pagination$(): Observable<Pagination | null> {
    return this._pagination.asObservable();
  }

  private _branchData: BehaviorSubject<BranchContent | undefined> =
    new BehaviorSubject<BranchContent | undefined>(undefined);
  get branchData$(): Observable<BranchContent | undefined> {
    return this._branchData.asObservable();
  }

  private _branchList: BehaviorSubject<BranchDataList> =
    new BehaviorSubject<BranchDataList>([]);
  get branchList$(): Observable<BranchDataList> {
    return this._branchList.asObservable();
  }

  protected baseUrl = `${environment.service.host}`;
  constructor(private httpClient: HttpClient) {}

  fetchMerchantDetailByBranchSK(
    branchSK: string,
    latitude?: string,
    longitude?: string
  ): Observable<MerchantData | undefined> {
    return this.httpClient
      .post(
        `${this.baseUrl}/branch-detail`,
        { content: { branchSK, latitude, longitude } },
        { responseType: 'text' }
      )
      .pipe(
        map((textResponse: string): MerchantData | undefined => {
          const { content } = JSON.parse(textResponse) as MerchantResponse;
          if (content) {
            const isHaveOpenClose =
              content.openClose && content.openClose.length;
            const isHaveLocation = !!content.location;

            const openClose = isHaveOpenClose
              ? content.openClose.map((item: any) => ({
                  open: item.open.toString(),
                  close: item.close.toString(),
                }))
              : [];

            const location = isHaveLocation
              ? {
                  latitude: content.location.lat,
                  longitude: content.location.lon,
                }
              : null;

            return { ...content, openClose, location };
          } else {
            return undefined;
          }
        })
      );
  }

  fetchMyPageByBranchSKAndKay(
    branchSK: string,
    key: string
  ): Observable<MerchantData | undefined> {
    return this.httpClient
      .post(
        `${this.baseUrl}/my-page`,
        { content: { branchSK, key } },
        { responseType: 'text' }
      )
      .pipe(
        map((textResponse: string): MerchantData | undefined => {
          const { content } = JSON.parse(textResponse) as MerchantResponse;
          if (content) {
            const isHaveOpenClose =
              content.openClose && content.openClose.length;
            const isHaveLocation = !!content.location;

            const openClose = isHaveOpenClose
              ? content.openClose.map((item: any) => ({
                  open: item.open.toString(),
                  close: item.close.toString(),
                }))
              : [];

            const location = isHaveLocation
              ? {
                  latitude: content.location.lat,
                  longitude: content.location.lon,
                }
              : null;

            return { ...content, openClose, location };
          } else {
            return undefined;
          }
        })
      );
  }

  fetchBranchList(filterData: BranchFilter): Observable<BranchContent> {
    // console.log('filterData', filterData);
    const filterTemplate = {
      content: {
        ...filterData,
        filter: [],
        searchText: '',
      },
    };

    return this.httpClient
      .post(`${this.baseUrl}/search`, filterTemplate, { responseType: 'text' })
      .pipe(
        map((textResponse: string) => {
          const objectResult = JSON.parse(textResponse) as BranchResponse;
          return objectResult.content as BranchContent;
        }),
        tap(({ totalData, branch }: BranchContent) => {
          const currentPagination: Pagination = {
            page: filterTemplate.content.page,
            totalCount: totalData,
            pageSize: filterTemplate.content.page,
          };
          // console.log({ totalData, branch });

          this._pagination.next(currentPagination);
          this._branchData.next({ totalData, branch });
          this._branchList.next(branch);
        })
      );
  }
}
