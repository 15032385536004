import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { environment } from 'environment';
import {
  BehaviorSubject,
  filter,
  find,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { SecurityHelper } from '../helpers/security-helper';
import {
  Adverties,
  CategoryCodeNumber,
  CategoryMasterDataItem,
  FacilityGroupValue,
  MasterDataContent,
  MasterDataItem,
  MasterDataItemDTO,
} from '../models/master-data.model';
import { ProvinceModel } from '../models/province.model';
import { MasterDataStore } from '../stored/masterdata/master-data.store';
import { latlng } from './lat-lng.service';

// const currentHostUrl = window.location.href.split('/').slice(0, 3).join('/');

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  protected key = environment.security.publicKey;
  private _paymentMasterList: BehaviorSubject<MasterDataItem[]> =
    new BehaviorSubject<MasterDataItem[]>([]);
  get paymentMasterList$(): Observable<MasterDataItem[]> {
    return this._paymentMasterList.asObservable();
  }

  private _categoryMasterList: BehaviorSubject<CategoryMasterDataItem[]> =
    new BehaviorSubject<CategoryMasterDataItem[]>([]);
  get categoryMasterList$(): Observable<CategoryMasterDataItem[]> {
    return this._categoryMasterList.asObservable();
  }

  private _facilityMasterList: BehaviorSubject<MasterDataItem[]> =
    new BehaviorSubject<MasterDataItem[]>([]);
  get facilityMasterList$(): Observable<MasterDataItem[]> {
    return this._facilityMasterList.asObservable();
  }

  private _provinceMasterList: BehaviorSubject<ProvinceModel[]> =
    new BehaviorSubject<ProvinceModel[]>([]);
  get provinceMasterList$(): Observable<ProvinceModel[]> {
    return this._provinceMasterList.asObservable();
  }

  private _advertisement: BehaviorSubject<Adverties[]> = new BehaviorSubject<
    Adverties[]
  >([]);
  get advertisement$(): Observable<Adverties[]> {
    return this._advertisement.asObservable();
  }

  constructor(
    private httpClient: HttpClient,
    private masterDataStore: MasterDataStore
  ) {}

  getProvinceList(): Observable<ProvinceModel[]> {
    return this.httpClient
      .get('/assets/json/lat-lng-each-of-province.json')
      .pipe(
        switchMap((response: any) => {
          const responseResult = response as ProvinceModel[];
          this._provinceMasterList.next(responseResult);
          this.masterDataStore.update(() => {
            return {
              provinceList: responseResult,
            };
          });
          return of(responseResult);
        })
      );
  }

  // getProvinceByCode(code: string): Observable<ProvinceModel> {
  //   // return this.httpClient
  //   //   .get('/assets/json/lat-lng-each-of-province.json')
  //   //   .pipe(
  //   //     switchMap((response: any) => {
  //   //       const responseResult = response as ProvinceModel[];
  //   //       this._provinceMasterList.next(responseResult);
  //   //       return of(responseResult);
  //   //     })
  //   //   );
  // }

  private getMasterDataFromLocal(): string | null {
    try {
      const edtString: string = localStorage.getItem('edt') || '';
      const expireDateString = SecurityHelper.Decrypt(edtString, this.key);
      if (!expireDateString) return null;
      const expireDate = dayjs(JSON.parse(expireDateString));
      if (!expireDate.isValid()) return null;
      const currentDate = dayjs();
      const isNotExpire = expireDate.diff(currentDate, 'second', true) > 0;
      if (isNotExpire) {
        const rawString: string | null = localStorage.getItem('md') || '';
        const decrypt = SecurityHelper.Decrypt(rawString, this.key);
        const content = JSON.parse(decrypt);
        return typeof content === 'object' ? decrypt : null;
      } else {
        return null;
      }
    } catch (error) {
      console.error();
      return null;
    }
  }

  fetchMasterData(): Observable<any> {
    const localResponse = this.getMasterDataFromLocal();
    const url = `${environment.service.host}/config/filter`;
    const source$ =
      localResponse === null
        ? this.httpClient.post(url, {}, { responseType: 'text' })
        : of(localResponse);

    return source$.pipe(
      map((textResponse) => {
        const { content = undefined } =
          (JSON.parse(textResponse) as {
            content: MasterDataContent;
          }) || {};
        if (content) {
          const {
            category = [],
            facilies = [],
            location = [],
            paymentMethod = [],
            promotion: promotionMasterList = [],
            status: statusMasterList = [],
            expireDateTime = null,
            advertises = [],
          } = content;
          localStorage.setItem(
            'edt',
            SecurityHelper.Encrypt(JSON.stringify(expireDateTime), this.key)
          );
          const faciliesMasterList = facilies.map((item: MasterDataItemDTO) => {
            const iconUrl = `${
              environment.webUrl
            }/assets/img/icon/facilities/${this.buildNameFromCode(
              item.code
            )}.svg`;
            return { ...item, iconUrl };
          });
          const paymentMethodMasterList = paymentMethod.map(
            (item: MasterDataItemDTO) => {
              if (item.code === 'loyalty_point') {
                const { partner = [] } = item;

                const result: MasterDataItem[] =
                  partner.map((partner: MasterDataItemDTO) => {
                    const iconUrl = `${environment.webUrl}/assets/img/icon/payments/${partner.code}.svg`;
                    return { ...partner, iconUrl };
                  }) || [];
                Object.assign(item, { partner: result });
              }
              const iconUrl = `${environment.webUrl}/assets/img/icon/payments/${item.code}.svg`;
              return { ...item, iconUrl };
            }
          );

          const categoryMasterList = category.map(
            (item: MasterDataItemDTO): CategoryMasterDataItem => {
              return {
                ...item,
                iconUrl: '',
                subcodeList: CategoryCodeNumber[item.code] || [],
              };
            }
          );

          // set data to behaviorSubject
          this._categoryMasterList.next(categoryMasterList);
          this._facilityMasterList.next(faciliesMasterList);
          this._paymentMasterList.next(paymentMethodMasterList);
          this._advertisement.next(advertises);

          const returnResponse: { [key: string]: any } = {
            categoryMasterList,
            faciliesMasterList,
            location,
            paymentMethodMasterList,
            promotionMasterList,
            statusMasterList,
          };

          const paymentMethodMasterListHideGloAtk =
            paymentMethodMasterList.filter((payment: any) => {
              return payment.code !== 'atk';
            });

          const storeMaster: { [key: string]: any } = {
            facilieList: faciliesMasterList,
            categoryList: categoryMasterList,
            paymentList: paymentMethodMasterListHideGloAtk, //paymentMethodMasterList
            promotionList: promotionMasterList,
          };

          this.masterDataStore.update(() => {
            return storeMaster;
          });

          localStorage.setItem(
            'md',
            SecurityHelper.Encrypt(textResponse, this.key)
          );

          // Object.keys(returnResponse).forEach((key) => {
          //   localStorage.setItem(key, JSON.stringify(returnResponse[key]));
          // });

          return returnResponse;
        } else {
          return undefined;
        }
      })
    );
  }

  getCurrentUserLocation(): Observable<GeolocationPosition | undefined> {
    return new Observable((observer) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          observer.next(position);
          observer.complete();
        },
        () => {
          observer.next(undefined);
          observer.complete();
        }
      );
    });
  }

  private buildNameFromCode(code: string): string {
    const codeSplitList = code.split('_') || [''];
    codeSplitList.shift();
    const name = codeSplitList.join('-').toLowerCase();
    return name;
  }

  getGroupFacilieList(type: string) {
    return FacilityGroupValue[type];
  }

  getCategoryName(categoryCode: string): string {
    const categoryResult: any = this.masterDataStore
      .getValue()
      .categoryList.find(({ code }) => {
        return code === categoryCode;
      });
    return categoryResult?.name;
  }

  getPaymentMethodName(paymentCode: string): string {
    let paymentNameList: Array<any> = [];
    paymentNameList = this.masterDataStore
      .getValue()
      .paymentList.filter((payment: any) => {
        return payment.code !== 'loyalty_point';
      });

    this.masterDataStore.getValue().paymentList.map((payment: any) => {
      if (payment.code === 'loyalty_point') {
        paymentNameList = paymentNameList.concat(payment.partner);
        return;
      }
    });

    const paymentMethodResult: any = paymentNameList.find((list: any) => {
      return list.code === paymentCode;
    });

    return paymentMethodResult?.name;
  }

  getFacilityName(facilityCode: string): string {
    const facilityResult: any = this.masterDataStore
      .getValue()
      .facilieList.find(({ code }) => {
        return code === facilityCode;
      });
    return facilityResult?.name;
  }

  getPromotionName(promotionCode: string): string {
    const promotionResult: any = this.masterDataStore
      .getValue()
      .promotionList.find(({ code }) => {
        return code === promotionCode;
      });
    return promotionResult?.name;
  }
}
