import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ProvinceSelectorComponent } from './components/province-selector/province-selector.component';
import { SearchBarWidgetComponent } from './components/search-bar-widget/search-bar-widget.component';
import { SearchBarFixedComponent } from './search-bar-fixed/search-bar-fixed.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
@NgModule({
  declarations: [
    SearchBarComponent,
    ProvinceSelectorComponent,
    SearchBarFixedComponent,
    SearchBarWidgetComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
  ],

  exports: [SearchBarComponent, SearchBarFixedComponent],
})
export class SearchBarsModule {}
