import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { InformationComponent } from './infomation/information.component';

@NgModule({
  declarations: [InformationComponent],
  imports: [CommonModule, TranslateModule],
  exports: [InformationComponent],
})
export class ModalModule {}
