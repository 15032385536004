import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { FilterValueState } from './filter-value.state';

export function createInitialFilterValueState(): FilterValueState {
  return {
    searchText: '',
    filter: [],
    location: {
      id: '1',
      code: '010',
      province: 'กรุงเทพมหานคร',
      lat: '13.7563309',
      lng: '100.5017651',
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filterValue' })
export class FilterValueStore extends Store<FilterValueState> {
  constructor() {
    super(createInitialFilterValueState());
  }
}
