import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from '../views/layouts/main/main.component';
import { MapComponent } from '../views/layouts/map/map.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../views/pages/landings/landings.module').then(
            (m) => m.LandingsModule
          ),
      },
      {
        path: 'product',
        loadChildren: () =>
          import('../views/pages/products/products.module').then(
            (m) => m.ProductsModule
          ),
      },
      {
        path: 'detail',
        loadChildren: () =>
          import('../views/pages/details/details.module').then(
            (m) => m.DetailsModule
          ),
      },
      {
        path: 'kiosk',
        loadChildren: () =>
          import('../views/pages/kiosk/kiosk.module').then(
            (m) => m.KioskModule
          ),
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () =>
          import(
            '../views/pages/terms-and-conditions/terms-and-conditions.module'
          ).then((m) => m.TermsAndConditionsModule),
      },
    ],
  },
  {
    path: 'map',
    component: MapComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../views/pages/maps/maps.module').then((m) => m.MapsModule),
      },
    ],
  },
  {
    path: 'recommend',
    loadChildren: () =>
      import('../views/pages/recommend/recommend.module').then(
        (m) => m.RecommendModule
      ),
  },
  // {
  //   path: 'demo',
  //   component: DemoComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('../views/pages/demo/demo.module').then((m) => m.DemoModule),
  //     },
  //   ],
  // },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
