import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { map, Observable } from 'rxjs';
import { PromotionResponse } from '../models';

export interface PromotionServiceRequest {
  latitude: string;
  longitude: string;
  randomSelectFrom: number;
  maxSelect: number;
}
@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(private http: HttpClient) {}

  public getPromotion(
    request: PromotionServiceRequest
  ): Observable<PromotionResponse> {
    const url = `${environment.service.host}/promotion`;
    return this.http
      .post(url, { content: request }, { responseType: 'text' })
      .pipe(map((_) => JSON.parse(_)));
  }
}
