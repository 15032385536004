import 'zone.js/dist/zone';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import * as Klaro from 'klaro/dist/klaro-no-css';
// eslint-disable-next-line @typescript-eslint/no-var-requires
// const Klaro = require('klaro');
// import "klaro/dist/klaro.css"

// const config = {
//   translations: {
//     en: {
//       googleAnalytics: {
//         title: 'Google Analytics',
//         description:
//           'The analytics service ran by a most definitely non-evil company.',
//       },
//       purposes: {
//         analytics: 'Analytics',
//         styling: 'Styling',
//       },
//     },
//   },
//   apps: [
//     {
//       name: 'Google Analytics',
//       default: false,
//       title: 'Google Analytics',
//       purpose: ['analytics'],
//       cookies: ['_ga'],
//     },
//   ],
// };

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  // we set up Klaro with the config
  // Klaro.setup(config);
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
