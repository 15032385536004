import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs';
import { FilterItem, ProvinceModel, SearchModel } from '../models';
import { MasterDataStore, SearchQuery } from '../stored';

@Injectable({
  providedIn: 'platform',
})
export class QueryParamService {
  constructor(
    private router: Router,
    private searchQuery: SearchQuery,
    private masterDataStore: MasterDataStore,
    private activatedRoute: ActivatedRoute
  ) {
    this.searchQuery //For rebuild parameter
      .select()
      .pipe(distinctUntilChanged())
      .subscribe({
        next: (value: SearchModel) => {
          const queryParams = this.buildQueryParam(value);
          console.warn('buildParam', { value, queryParams });
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            // queryParamsHandling: 'merge',
          });
        },
      });
  }

  buildQueryParam(filterValue: SearchModel): { [key: string]: any } {
    const {
      code,
      keyword,
      lat,
      lng,
      filterList = [],
      page = null,
    } = filterValue;

    const promotion = filterList?.find((item) => item.code === 'PROMO_PROMO');
    const discount = filterList.find((item) => item.code === 'PROMO_DISCOUNT');
    const category = filterList.find((item) => item.code === 'CATEGORY');
    const storeImage = filterList.find((item) => item.code === 'STATUS_IMAGE');
    const storeOpen = filterList.find((item) => item.code === 'STATUS_OPEN');
    const storeFront = filterList.find(
      (item) => item.code === 'STATUS_STOREFRONT'
    );
    const payment = filterList
      .filter((item) => item.code === 'PAYMENT_METHOD')
      .map((i) => i.value);

    const facility = filterList
      .filter((item) => item.code === 'FACILITY')
      .map(({ value }: { value: string }) => {
        const sliptvalue = value.split('_');
        sliptvalue.shift();
        return sliptvalue.length > 1
          ? sliptvalue.join('_').toLowerCase()
          : sliptvalue[0].toLowerCase();
      });

    const queryParams = {};

    code && Object.assign(queryParams, { area: code });
    code === 'map_pin' && Object.assign(queryParams, { lat, lng });
    keyword && Object.assign(queryParams, { keyword });

    category &&
      Object.assign(queryParams, {
        category: category.value.split('_')[1].toLowerCase(),
      });

    payment.length > 0 &&
      Object.assign(queryParams, { payment: payment.join(',') });
    facility.length > 0 &&
      Object.assign(queryParams, {
        facility: facility.join(','),
      });
    storeImage && Object.assign(queryParams, { storeImage: !!storeImage });
    storeOpen && Object.assign(queryParams, { storeOpen: !!storeOpen });

    promotion && Object.assign(queryParams, { promotion: !!promotion });
    discount && Object.assign(queryParams, { discount: !!discount });
    Object.assign(queryParams, { storeFront: storeFront?.value === true });
    page && Object.assign(queryParams, { page });

    return queryParams;
  }

  getProvinceByCode(code: string): ProvinceModel | null {
    if (!code) return null;
    const provinceMasterList =
      this.masterDataStore.getValue().provinceList || [];
    const provincebyCode = provinceMasterList.find(
      (item) => item.code === code
    );
    return provincebyCode ? provincebyCode : null;
  }

  getCategory(name: string): FilterItem | null {
    if (!name) return null;

    const categoryMaster = this.masterDataStore.getValue().categoryList || [];
    const categoryResult = categoryMaster.find(({ code }) => {
      const categoryCodeforCompare = code.split('_')[1].toLowerCase();
      return categoryCodeforCompare === name;
    });
    return categoryResult
      ? { code: 'CATEGORY', value: categoryResult.code }
      : null;
  }

  getFacilityList(facilityQuery: string): FilterItem[] {
    if (!facilityQuery) return [];
    const facilityMaster = this.masterDataStore.getValue().facilieList || [];
    const facilityRawList = facilityQuery.split(',');
    const facilityList: FilterItem[] = [];
    facilityRawList.forEach((itemName) => {
      const result = facilityMaster.find(({ code }) => {
        const codeName = code.split('_');
        codeName.shift();
        const facNameBycode =
          codeName.length > 1
            ? codeName.join('_').toLowerCase()
            : codeName[0].toLowerCase();
        return facNameBycode === itemName;
      });
      result && facilityList.push({ code: 'FACILITY', value: result.code });
    });
    return facilityList;
  }

  getPaymentList(paymentQuery: string): FilterItem[] {
    if (!paymentQuery) return [];
    const paymentMaster = this.masterDataStore.getValue().paymentList || [];
    const loyaltyPointPartner =
      paymentMaster.find(({ code }) => code === 'loyalty_point')?.partner || [];

    const paymentRawList = paymentQuery.split(',');
    const paymentList: FilterItem[] = [];
    paymentRawList.forEach((itemName) => {
      const isLoyaltyPoint = itemName.includes('loyalty_point');
      const master: any[] = isLoyaltyPoint
        ? loyaltyPointPartner
        : paymentMaster;

      const result = master.find(({ code }) => code === itemName);
      result &&
        paymentList.push({ code: 'PAYMENT_METHOD', value: result.code });
    });
    return paymentList;
  }
}

// http://localhost:4200/product
// ?keyword=ก๋วยเตี๋ยว
// &area=010
// &province=%E0%B8%81%E0%B8%A3%E0%B8%B8%E0%B8%87%E0%B9%80%E0%B8%97%E0%B8%9E%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%99%E0%B8%84%E0%B8%A3
// &category=10001,10007,50001
// &payment=energy,atk,half_each,topup_card,travel_tour,travel_together,welfare,welfare_gas,promptpay,glo
// &facility=vaccinated,wifi,park,restroom,air_conditioner,wheelchair,pet,children,smoking_area,book_in_advance,outside_seat,inside_seat,working_space,alcohol,live_music,meeting_room,ev_charger
// &storeImage=true
// &promotion=true
// &discount=true
// ผลการค้นหา "ก๋วยเตี๋ยว" รวมฮิตร้านติดโปรอาหารและเครื่องดื่ม, ชุดตรวจโควิดฯ, วินเซฟ, คนละครึ่ง, และช่องทางอื่นๆ ในกรุงเทพมหานคร
