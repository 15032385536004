import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { map, Observable, tap } from 'rxjs';
import { MarketInquiryRequest, MarketInquiryResponse } from '../models';

const httpOptions = {
  headers: new HttpHeaders({
    reqID: '',
    reqDtm: '',
    'content-type': 'application/json;charset=UTF-8',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class MarketInquiryService {
  constructor(private http: HttpClient) {}

  public marketInquiry(
    payload: MarketInquiryRequest
  ): Observable<MarketInquiryResponse> {
    const url = `${environment.service.host}/market-inquiry`;
    return this.http
      .post(url, payload, { responseType: 'text' })
      .pipe(map((_) => JSON.parse(_)));
  }
}
