/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';
// for config  Screen size By enum
export enum ScreenSize {
  xs = 0,
  sm = 576,
  ssm = 745,
  md = 768,
  smd = 834,
  lg = 992,
  xl = 1200,
  xxl = 1400,
}

export const ScreenSizePriority: { [key: string]: number } = {
  xxl: 0,
  xl: 1,
  lg: 2,
  md: 3,
  smd: 4,
  sm: 5,
  ssm: 6,
  xs: 7,
};

@Injectable({
  providedIn: 'platform',
})
export class MediaWatcherService {
  protected screenConfig = {
    xs: `(min-width: ${ScreenSize.xs}px)`,
    sm: `(min-width: ${ScreenSize.sm}px)`,
    ssm: `(min-width: ${ScreenSize.ssm}px)`,
    md: `(min-width: ${ScreenSize.md}px)`,
    smd: `(min-width: ${ScreenSize.smd}px)`,
    lg: `(min-width: ${ScreenSize.lg}px)`,
    xl: `(min-width: ${ScreenSize.xl}px)`,
    xxl: `(min-width: ${ScreenSize.xxl}px)`,
  };

  private _onMediaChange: ReplaySubject<{
    matchingAliases: string[];
    matchingQueries: any;
  }> = new ReplaySubject<{ matchingAliases: string[]; matchingQueries: any }>(
    1
  );

  private _screenSize: BehaviorSubject<keyof typeof ScreenSize> =
    new BehaviorSubject(`xs` as keyof typeof ScreenSize);

  constructor(private _breakpointObserver: BreakpointObserver) {
    this._breakpointObserver
      .observe(Object.values(this.screenConfig))
      .pipe(
        map((state) => {
          const matchingAliases: string[] = [];
          const matchingQueries: any = {};

          const matchingBreakpoints =
            Object.entries(state.breakpoints).filter(
              ([query, matches]) => matches
            ) ?? [];
          for (const [query] of matchingBreakpoints) {
            const matchesData = Object.entries(this.screenConfig).find(
              ([alias, q]) => q === query
            );
            if (matchesData) {
              matchingAliases.push(matchesData[0]);
              matchingQueries[matchesData[0]] = query;
            }
          }

          const currentScreenSize =
            matchingAliases[matchingAliases.length - 1] || 'xs';
          this._screenSize.next(currentScreenSize as keyof typeof ScreenSize);

          this._onMediaChange.next({
            matchingAliases,
            matchingQueries,
          });
        })
      )
      .subscribe();
  }

  get onMediaChange$(): Observable<{
    matchingAliases: string[];
    matchingQueries: any;
  }> {
    return this._onMediaChange.asObservable();
  }

  get screenSize$(): Observable<keyof typeof ScreenSize> {
    return this._screenSize.asObservable();
  }

  onMediaQueryChange$(query: string | string[]): Observable<BreakpointState> {
    return this._breakpointObserver.observe(query);
  }
}
