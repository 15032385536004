import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { QueryParamState } from './query-param.state';

export function createInitialQueryParamState(): QueryParamState {
  return {
    area: undefined,
    storeFront: undefined,
    page: undefined,
    category: undefined,
    payment: undefined,
    storeImage: undefined,
    storeOpen: undefined,
    promotion: undefined,
    discount: undefined,
    keyword: undefined,
    facility: undefined,
    lat: undefined,
    lng: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'queryParam', resettable: true })
export class QueryParamStore extends Store<QueryParamState> {
  constructor() {
    super(createInitialQueryParamState());
  }
}
