import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  PortalInjector,
} from '@angular/cdk/portal';
import { Subject } from 'rxjs';

interface MyOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  disableClose?: boolean;
  data?: any;
  height?: string | number;
  width?: string | number;
  position?: string;
}

const DEFAULT_CONFIG: MyOverlayConfig = {
  hasBackdrop: true,
};

export const GENERIC_MODAL_DATA = new InjectionToken<any>('GENERIC_MODAL_DATA');

@Injectable()
export class GenericModalService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  private getOverlayConfig(config: MyOverlayConfig): OverlayConfig {
    let positionStrategy;
    if (config.position === 'bottom') {
      positionStrategy = this.overlay.position().global().bottom();
    } else {
      positionStrategy = this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();
    }

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      height: config.height,
      width: config.width,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });
  }

  private createOverlay(config: MyOverlayConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);

    return this.overlay.create(overlayConfig);
  }

  private createInjector(
    config: MyOverlayConfig,
    dialogRef: GenericDialogRef
  ): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(GenericDialogRef, dialogRef);
    injectionTokens.set(GENERIC_MODAL_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  open(config: MyOverlayConfig, component: ComponentType<any>) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const overlay = this.createOverlay(dialogConfig);
    const dialogRef = new GenericDialogRef(overlay);
    const injector = this.createInjector(dialogConfig, dialogRef);
    const portal = new ComponentPortal(component, null, injector);

    overlay.attach(portal);
    overlay.backdropClick().subscribe(() => {
      if (!config.disableClose) {
        dialogRef.close();
      }
      dialogRef.backdropClick.next(null);
    });

    return dialogRef;
  }
}

export class GenericDialogRef {
  afterClosed = new Subject<any>();
  backdropClick = new Subject<any>();

  constructor(private overlayRef: OverlayRef) {}

  config(): OverlayConfig {
    return this.overlayRef.getConfig();
  }

  close(data?: any): void {
    this.overlayRef.dispose();

    this.afterClosed.next(data);
    this.afterClosed.complete();
  }
}
