import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SearchState } from './search.state';
import { SearchStore } from './search.store';

@Injectable({ providedIn: 'root' })
export class SearchQuery extends Query<SearchState> {
  constructor(private searchStore: SearchStore) {
    super(searchStore);
  }
}
