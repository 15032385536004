import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

export interface MetaData {
  title: string;
  keyWord: string;
  description: string;
  imageUrl: string;
  imageSecureUrl: string;
  alt: string;
  imageType: string;
}

@Injectable({
  providedIn: 'platform',
})
export class SEOService {
  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private titleSvc: Title,
    private metaSvc: Meta
  ) {}

  updateTitle(title: string): void {
    this.titleSvc.setTitle(title);
    const metetagList: MetaDefinition[] = [
      {
        property: 'og:title',
        content: title,
      },
      {
        property: 'twitter:title',
        content: title,
      },
      {
        property: 'og:site_name',
        content: title,
      },
    ];
    this.upSertTag(metetagList);
  }

  updateDescription(description: string): void {
    const metetagList: MetaDefinition[] = [
      {
        name: 'description',
        content: description,
      },
      {
        property: 'og:description',
        content: description,
      },
      {
        property: 'twitter:description',
        content: description,
      },
    ];
    this.upSertTag(metetagList);
  }

  updateImage(
    imageUrl: string,
    imageWidth: number,
    imageHeigh: number,
    imageType: string,
    alt: string
  ): void {
    const metetagList: MetaDefinition[] = [
      {
        property: 'og:image:type',
        content: imageType,
      },
      {
        property: 'og:image',
        content: imageUrl,
      },
      {
        property: 'twitter:image',
        content: imageUrl,
      },
      {
        property: 'og:image:secure_url',
        content: imageUrl,
      },
      {
        property: 'og:image:width',
        content: imageWidth.toString(),
      },
      {
        property: 'og:image:height',
        content: imageHeigh.toString(),
      },
      {
        property: 'og:image:alt',
        content: alt,
      },
    ];
    this.upSertTag(metetagList);
  }

  updateUrl(url: string): void {
    const metetagList: MetaDefinition[] = [
      {
        property: 'og:url',
        content: url,
      },
      {
        property: 'twitter:url',
        content: url,
      },
    ];
    this.upSertTag(metetagList);
  }

  private upSertTag(metetagList: MetaDefinition[]): void {
    metetagList.forEach((tagItem) => {
      const { name = null, property = null } = tagItem;
      (name || property) &&
        (() => {
          const isHaveElement = !!this.metaSvc.getTag(
            `${name ? 'name' : 'property'}="${name ? name : property}"`
          );
          isHaveElement
            ? this.metaSvc.updateTag(tagItem)
            : this.metaSvc.addTag(tagItem);
        })();
    });
  }

  createCanonicalLink(url: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    this.dom.head.appendChild(link);
  }

  createHreflangLink(url: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('href', url);
    link.setAttribute('hreflang', 'th_TH');
    this.dom.head.appendChild(link);
  }
}
