import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { LoggingInterceptorService } from './core/logging.interceptor.service';
import { EventInterceptorService } from './core/event-interceptor.service';
import { ErrorCatchingInterceptorService } from './core/error-catching-interceptor.service';

export { PokemonFetchService } from './pokemon-fetch.service';
export { LatLngService } from './lat-lng.service';
export { PromotionService } from './promotion.service';
export { SearchService } from './search.service';
export { ConfigFilterService } from './config-filter.service';
export { DetailsDataService } from './details.service';
export { MasterDataService } from './master-data.service';
export { MarketInquiryService } from './market-inquiry.service';
export { RecommendFilterService } from './recommend-filter.service';
export { EncryptionService } from './core/encryption.service';
export { DetailsKioskDataService } from './details-kiosk.service';

export const LoggingInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LoggingInterceptorService,
  multi: true,
};

export const EventInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: EventInterceptorService,
  multi: true,
};

export const ErrorCatchingInterceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorCatchingInterceptorService,
  multi: true,
};
