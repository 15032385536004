import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { DataLayerService } from './services/datalayer.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SearchFilterService } from '@tung-ngern/ui';
import { SEOService } from '@tung-ngern/common';

@Component({
  selector: 'tung-ngern-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public environment = environment;

  private webUrl = window.location.href;

  constructor(
    private title: Title,
    private metaTagService: Meta,
    // private _dataLayerService: DataLayerService,
    // private _router: Router,
    private activatedRoute: ActivatedRoute,
    private seoService: SEOService
  ) {
    this.redirectUrl();
    this.seoService.createCanonicalLink(this.webUrl);
    this.seoService.createHreflangLink(this.webUrl);
    // this.seoService.updateUrl(this.webUrl);

    if (!environment.production) {
      this.title.setTitle(` (${environment.version})`);
      // this.metaTagService.updateTag({
      //   name: 'robots',
      //   content: 'noindex, nofollow',
      // });
    } else {
      // this.metaTagService.updateTag({
      //   name: 'robots',
      //   content: 'index, follow',
      // });
    }
  }

  redirectUrl() {
    if (
      window?.location?.origin === 'https://www.xn--12clab5ekl9eg1oqbg2hxc.com'
    ) {
      window.location.replace(
        'https://tungngern.krungthai.com' +
          window.location.pathname +
          window.location.search
      );
    }
  }

  ngOnInit(): void {
    console.log('Initial App');

    // const htmlElement = document.getElementsByClassName('cwc-banner-desc')[0];
    // htmlElement.
    // console.log({ htmlElement });

    // this.metaTagService.addTags([
    //   {
    //     name: 'keywords',
    //     content:
    //       'ถุงเงินกรุงไทย, tungngern, ร้านค้าถุงเงิน,ร้านค้าถุงเงิน รวมทุกร้านค้า ค้นหาทุกย่านทั่วไทย, ค้นหาร้านค้าถุงเงิน',
    //   },
    //   { name: 'author', content: 'ถุงเงินกรุงไทย' },
    //   {
    //     name: 'description',
    //     content:
    //       'รวมร้านค้าทุกร้านบนแอพพลิเคชั่นถุงเงินกรุงไทย ทั่วทุกย่าน ทั่วทั้งประเทศไทย  แค่สมัครร้านค้ากับแอพพลิเคชั่นถุงเงินกรุงไทยวันนี้ฟรี ไม่มีค่าใช้จ่าย คลิกเลย!',
    //   },

    //   //------------- Social media Meta Tag Start -------------//
    //   { property: 'og:title', content: `${this.title.getTitle()}` },
    //   { property: 'og:site_name', content: `${this.title.getTitle()}` },
    //   { property: 'og:type', content: 'website' },
    //   {
    //     property: 'og:image',
    //     content: `${this.webUrl}/assets/img/tn-logo.png`,
    //   },
    //   {
    //     property: 'og:image:secure_url',
    //     content: `${this.webUrl}/assets/img/tn-logo.png`,
    //   },
    //   {
    //     property: 'og:image:type',
    //     content: 'image/png',
    //   },
    //   {
    //     property: 'og:image:width',
    //     content: '167',
    //   },
    //   {
    //     property: 'og:image:height',
    //     content: '90',
    //   },
    //   {
    //     property: 'og:image:alt',
    //     content: 'ถุงเงิน โปรโมทร้านค้าถุงเงินฟรี มีแต่ได้ ยอดขายปัง',
    //   },

    //   { property: 'og:url', content: `${this.webUrl}` },
    //   {
    //     property: 'og:description',
    //     content: `รวมร้านค้าทุกร้านบนแอพพลิเคชั่นถุงเงินกรุงไทย ทั่วทุกย่าน ทั่วทั้งประเทศไทย แค่สมัครร้านค้ากับแอพพลิเคชั่นถุงเงินกรุงไทยวันนี้ฟรี ไม่มีค่าใช้จ่าย คลิกเลย!`,
    //   },
    //   { property: 'og:locale', content: 'th_TH' },
    //   { property: 'og:locale:alternate', content: 'en_US' },
    //   { name: 'twitter:card', content: 'summary' },
    //   //------------- Social media Meta Tag End -------------//
    // ]);
  }

  // private receive(): void {
  //   console.log('Receiving...');
  //   window.addEventListener('message', (event) => {
  //     console.log('Receive event:', event);
  //     if (event.data) {
  //       localStorage.setItem('message', event.data);
  //     } else {
  //       localStorage.removeItem('message');
  //     }
  //   });
  // }
}
