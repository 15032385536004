import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CookieComponent } from './cookie/cookie.component';

@NgModule({
  declarations: [CookieComponent],
  imports: [CommonModule, TranslateModule],
  exports: [CookieComponent],
})
export class CookieModule {}
