import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalytics {
  constructor(private $gaService: GoogleAnalyticsService) {}

  public gaService(
    event_name: string,
    category_name: string,
    action_name: string,
    item_name: string,
    label_name?: string
  ) {
    if (label_name) {
      this.$gaService.gtag('event', event_name, {
        category_name: category_name,
        action_name: action_name,
        item_name: item_name,
        label_name: label_name,
      });
      return;
    } else {
      this.$gaService.gtag('event', event_name, {
        category_name: category_name,
        action_name: action_name,
        item_name: item_name,
      });
      return;
    }
  }
}
