import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, TranslateModule, NgxGoogleAnalyticsModule],
  exports: [HeaderComponent],
})
export class HeadersModule {}
