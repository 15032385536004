import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProductListColumn } from './product-list-column/product-list-column.component';
import { ResultList } from './result-list/result-list.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [ProductListColumn, ResultList],
  imports: [
    CommonModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    CarouselModule,
  ],
  exports: [ProductListColumn, ResultList],
})
export class SkeletonLoaderModule {}
