export class GeolocationCalculateHelper {
  public static calculateDistance(
    lat1?: number,
    lon1?: number,
    lat2?: number,
    lon2?: number
  ): number {
    const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180);

    if (lat1 && lon1 && lat2 && lon2) {
      const R = 6371; // รัศมีโลกเป็นกิโลเมตร
      const dLat = degreesToRadians(lat2 - lat1);
      const dLon = degreesToRadians(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) *
          Math.cos(degreesToRadians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c; // ระยะทางในหน่วยกิโลเมตร
    } else {
      return 20047; // ถ้าไม่มี lat, lon = set distance ให้ไกลที่สุด ( 20047 มาจากเส้นรอบวงโลก หาร 2 หน่วยเป็นกิโลเมตร )
    }
  }
}
