import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { PokemonState } from './pokemon.state';

export function createInitialState(): PokemonState {
  return {
    abilities: [],
    base_experience: 0,
    forms: [],
    game_indices: [],
    height: 0,
    held_items: [],
    id: 0,
    is_default: false,
    location_area_encounters: '',
    moves: [],
    name: '',
    order: 0,
    past_types: [],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    speciesModel: null as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sprites: null as any,
    stats: [],
    types: [],
    weight: 0,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'pokemon' })
export class PokemonStore extends Store<PokemonState> {
  constructor() {
    super(createInitialState());
  }
}
