import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  @Input()
  title: string;

  @Input()
  message: string;

  constructor(public activeModal: NgbActiveModal) {
    this.title = 'Alert!';
    this.message = '';
  }

  onClose(): void {
    this.activeModal.close();
  }
}
