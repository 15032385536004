import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { BehaviorSubject, map, of, Subject, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { BranchFilter, Pagination } from '../models';
import {
  KioskContent,
  KioskDetailCriteria,
  KioskFilter,
  KioskItem,
  KioskMapFilter,
  KioskModel,
} from '../models/kiosk.model';
import { GeolocationCalculateHelper } from '../../lib/helpers/geolocation-calculate.helper';

@Injectable({
  providedIn: 'root',
})
export class DetailsKioskDataService {
  private _pagination: Subject<Pagination> = new BehaviorSubject<Pagination>({
    totalCount: 0,
    page: 0,
    pageSize: 0,
  });
  get pagination$(): Observable<Pagination> {
    return this._pagination.asObservable();
  }

  private _kioskDataList: BehaviorSubject<KioskModel[]> = new BehaviorSubject<
    KioskModel[]
  >([]);
  get kioskList$(): Observable<KioskModel[]> {
    return this._kioskDataList.asObservable();
  }

  private _currentKeyword: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  get currentKeyword$(): Observable<string | undefined> {
    return this._currentKeyword.asObservable();
  }
  get currentKeyword(): string | undefined {
    return this._currentKeyword.getValue();
  }

  setCurrentKeyword(keyword: string) {
    this._currentKeyword.next(keyword);
  }

  constructor(private httpClient: HttpClient) {}

  getKioskDetail(
    criteria: KioskDetailCriteria
  ): Observable<KioskItem | undefined> {
    const { refNo, userLat = undefined, userLon = undefined } = criteria;
    const isAllowLocation = userLat && userLon;

    return this.httpClient
      .get(`${environment.storage.kiosk}`, { responseType: 'text' })
      .pipe(
        map((textResponse: string): KioskItem | undefined => {
          const content = JSON.parse(textResponse) as KioskModel[];
          const result = content.find((item) => item.refNo === refNo);
          if (!result) {
            return undefined;
          }
          return isAllowLocation
            ? (() => {
                const { lat: kioskLat = undefined, lon: kioskLon = undefined } =
                  result.location || {};
                const distance = GeolocationCalculateHelper.calculateDistance(
                  userLat,
                  userLon,
                  kioskLat,
                  kioskLon
                );
                return { ...result, distance };
              })()
            : result;
        })
      );
  }

  filterKioskList(filterData: KioskFilter): Observable<KioskContent> {
    return this.httpClient
      .get(`${environment.storage.kiosk}`, { responseType: 'text' })
      .pipe(
        map((textResponse: string): KioskContent => {
          const { page = 1, pageSize = 20 } = filterData;
          const jsonContent = JSON.parse(textResponse) as KioskModel[];
          const filterResult = this.filterKiosk(jsonContent, filterData);

          // calculate pagination
          const startIndex = (page - 1) * pageSize;
          const endIndex = startIndex + pageSize;
          const kioskList = filterResult.slice(startIndex, endIndex);

          const currentPagination: Pagination = {
            page: page,
            pageSize: pageSize,
            totalCount: filterResult.length,
          };

          this._pagination.next(currentPagination);
          this._kioskDataList.next(kioskList);

          return { totalData: filterResult.length, kioskList };
        })
      );
  }

  filterKioskMap(filterData: KioskMapFilter): Observable<KioskContent> {
    return this.httpClient
      .get(`${environment.storage.kiosk}`, { responseType: 'text' })
      .pipe(
        map((textResponse: string): KioskContent => {
          const jsonContent = JSON.parse(textResponse) as KioskModel[];
          const kioskList = this.filterKiosk(jsonContent, filterData);
          this._kioskDataList.next(kioskList);
          return { totalData: kioskList.length, kioskList };
        })
      );
  }

  private filterKiosk(
    masterData: KioskModel[],
    filter: KioskFilter | KioskMapFilter
  ): KioskItem[] {
    const {
      keyword = '',
      latitude: userLat = undefined,
      longitude: userLon = undefined,
    } = filter;

    const sourceData = keyword
      ? masterData.filter((item) => {
          const {
            name = '',
            refNo = undefined,
            address = undefined,
          } = item || {};
          const {
            zipCode = undefined,
            province = undefined,
            district = undefined,
            subDistrict = undefined,
            building = undefined,
            street = undefined,
            addressNo = undefined,
          } = address || {};

          let conditionString: string = name;
          if (refNo) conditionString += ` ${refNo}`;
          if (addressNo) conditionString += ` ${addressNo}`;
          if (building) conditionString += ` ${building}`;
          if (street) conditionString += ` ${street}`;
          if (subDistrict) conditionString += ` ${subDistrict}`;
          if (district) conditionString += ` ${district}`;
          if (province) conditionString += ` ${province}`;
          if (zipCode) conditionString += ` ${zipCode}`;

          return conditionString.includes(keyword);
        })
      : masterData;

    if (userLat && userLon) {
      const sourceDataWithDistance = sourceData.map((item) => {
        const { lat: kioskLat = undefined, lon: kioskLon = undefined } =
          item.location || {};
        const distance = GeolocationCalculateHelper.calculateDistance(
          userLat,
          userLon,
          kioskLat,
          kioskLon
        );
        return { ...item, distance };
      });
      return sourceDataWithDistance.sort((a, b) => a.distance - b.distance);
    } else {
      return sourceData.sort((a, b) =>
        a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })
      );
    }
  }
}
