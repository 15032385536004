<!-- <div class="d-flex flex-column">
  <div
    class="dialog-header d-flex justify-content-between align-items-center p-3"
  >
    <p class="m-0 title-text">{{ title }}</p>
    <button class="btn btn-close m-0" (click)="onClose()"></button>
  </div>
  <div class="dialog-body px-3 d-block">
    {{ message }}
  </div>
  <div class="dialog-foter d-flex justify-content-end align-items-center p-3">
    <button class="alert-accept-button" (click)="onClose()">
      {{ 'ตกลง' }}
    </button>
  </div>
</div> -->

<div class="d-flex flex-column p-4">
  <div class="d-flex justify-content-center my-2">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48ZM22 12.5C22 12.2239 22.2239 12 22.5 12H25.5C25.7761 12 26 12.2239 26 12.5V27.5C26 27.7761 25.7761 28 25.5 28H22.5C22.2239 28 22 27.7761 22 27.5V12.5ZM22.5 32C22.2239 32 22 32.2239 22 32.5V35.5C22 35.7761 22.2239 36 22.5 36H25.5C25.7761 36 26 35.7761 26 35.5V32.5C26 32.2239 25.7761 32 25.5 32H22.5Z"
        fill="#F49B00"
      />
    </svg>
  </div>
  <div class="d-flex justify-content-center my-2">
    <p class="m-0 title-text">{{ title }}</p>
  </div>
  <div class="d-flex justify-content-center my-2">
    <span class="detail-text">{{ message }}</span>
  </div>
  <div class="d-flex justify-content-center my-2">
    <button class="alert-accept-button" (click)="onClose()">
      {{ 'ตกลง' }}
    </button>
  </div>
</div>
