import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';

import { AppLocalizationModule, SEOService } from '@tung-ngern/common';
import {
  ErrorCatchingInterceptor,
  EventInterceptorProvider,
  LoggingInterceptorProvider,
} from '@tung-ngern/common/fetches';

import { AppRoutingModule } from './modules/app-routing.module';

import { AppComponent } from './app.component';

import { MainComponent } from './views/layouts/main/main.component';
import { MapComponent } from './views/layouts/map/map.component';

import { InitializeProvider } from './initialize';

import { environment } from '../environments/environment';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [AppComponent, MainComponent, MapComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    !environment.production ? AkitaNgDevtools.forRoot({ maxAge: 32 }) : [],
    AppRoutingModule,
    AppLocalizationModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbPaginationModule,
    // NgxGoogleAnalyticsModule.forRoot(environment.google.ga),
  ],
  providers: [
    InitializeProvider,
    ErrorCatchingInterceptor,
    !environment.production ? LoggingInterceptorProvider : [],
    EventInterceptorProvider,
    SEOService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
