import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LatLngService } from '@tung-ngern/common/fetches';
import {
  FilterItem,
  ProvinceModel,
  SearchModel,
} from '@tung-ngern/common/models';
import {
  MasterDataStore,
  QueryParamQuery,
  SearchStore,
} from '@tung-ngern/common/stored';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchFilterService {
  public isFiratInit = false;
  private geolocationPosition: GeolocationPosition | undefined;

  constructor(
    private searchStore: SearchStore,
    private masterDataStore: MasterDataStore,
    private latlngService: LatLngService,
    private queryParamQuery: QueryParamQuery
  ) {}

  init(): void {
    this.geolocationPosition = this.latlngService.currentUserLocation;
    this.queryParamQuery
      .select()
      .pipe(take(2))
      .subscribe((param) => {
        const lat = param.lat || '';
        const lng = param.lng || '';
        const categoryItem = this.getCategory(param.category || '');
        const facilityList = this.getFacilityList(param.facility || '');
        const paymentList = this.getPaymentList(param.payment || '');
        const locationObject = this.getLocationObjectByCode(
          param.area || '',
          param.area === 'map_pin'
            ? {
                lat,
                lng,
              }
            : undefined
        );

        const storeOpenItem = param.storeOpen
          ? { code: 'STATUS_OPEN', value: true }
          : null;
        const storeImageItem = param.storeImage
          ? { code: 'STATUS_IMAGE', value: true }
          : null;
        const discount = param.discount
          ? { code: 'PROMO_DISCOUNT', value: true }
          : null;
        const promotion = param.promotion
          ? { code: 'PROMO_PROMO', value: true }
          : null;

        const storeFront = {
          code: 'STATUS_STOREFRONT',
          value: param?.storeFront === true ? true : null,
        };

        const keyword = param.keyword;
        const page = param.page;

        const filterList = [categoryItem, ...facilityList, ...paymentList];

        storeOpenItem && filterList.push(storeOpenItem);
        storeImageItem && filterList.push(storeImageItem);
        discount && filterList.push(discount);
        promotion && filterList.push(promotion);

        filterList.push(storeFront);

        const result = {
          province: locationObject?.province || '',
          id: locationObject?.id || '',
          lat: locationObject?.lat || '',
          lng: locationObject?.lng || '',
          code: locationObject?.code || '',
          keyword: keyword || '',
          filterList: filterList.filter((item) => item) as FilterItem[],
          page: page ? parseInt(page) : 1,
        };
        locationObject && this.upDateStore(result);
      });
  }

  upDateStore(searchValue: SearchModel): void {
    this.searchStore.update(() => {
      return searchValue;
    });
  }

  initFilter(queryParamData: ActivatedRouteSnapshot): void {
    console.log({ queryParamData });
  }

  getCategory(name: string): FilterItem | null {
    if (!name) return null;

    const categoryMaster = this.masterDataStore.getValue().categoryList || [];
    const categoryResult = categoryMaster.find(({ code }) => {
      const categoryCodeforCompare = code.split('_')[1].toLowerCase();
      return categoryCodeforCompare === name;
    });
    return categoryResult
      ? { code: 'CATEGORY', value: categoryResult.code }
      : null;
  }

  getFacilityList(facilityQuery: string): FilterItem[] {
    if (!facilityQuery) return [];
    const facilityMaster = this.masterDataStore.getValue().facilieList || [];
    const facilityRawList = facilityQuery.split(',');
    const facilityList: FilterItem[] = [];
    facilityRawList.forEach((itemName) => {
      const result = facilityMaster.find(({ code }) => {
        const codeName = code.split('_');
        codeName.shift();
        const facNameBycode =
          codeName.length > 1
            ? codeName.join('_').toLowerCase()
            : codeName[0].toLowerCase();
        return facNameBycode === itemName;
      });
      result && facilityList.push({ code: 'FACILITY', value: result.code });
    });
    return facilityList;
  }

  getPaymentList(paymentQuery: string): FilterItem[] {
    if (!paymentQuery) return [];
    const paymentMaster = this.masterDataStore.getValue().paymentList || [];
    const loyaltyPointPartner =
      paymentMaster.find(({ code }) => code === 'loyalty_point')?.partner || [];

    const paymentRawList = paymentQuery.split(',');
    const paymentList: FilterItem[] = [];
    paymentRawList.forEach((itemName) => {
      const isLoyaltyPoint = itemName.includes('loyalty_point');
      const master: any[] = isLoyaltyPoint
        ? loyaltyPointPartner
        : paymentMaster;

      const result = master.find(({ code }) => code === itemName);
      result &&
        paymentList.push({ code: 'PAYMENT_METHOD', value: result.code });
    });
    return paymentList;
  }

  buildQueryParam(filterValue: SearchModel): { [key: string]: any } {
    const {
      code,
      keyword,
      lat,
      lng,
      filterList = [],
      page = null,
    } = filterValue;

    const promotion = filterList?.find((item) => item.code === 'PROMO_PROMO');
    const discount = filterList.find((item) => item.code === 'PROMO_DISCOUNT');
    const category = filterList.find((item) => item.code === 'CATEGORY');
    const storeImage = filterList.find((item) => item.code === 'STATUS_IMAGE');
    const storeOpen = filterList.find((item) => item.code === 'STATUS_OPEN');
    const storeFront = filterList.find(
      (item) => item.code === 'STATUS_STOREFRONT'
    );
    // console.log({ storeFront, filterList });
    const payment = filterList
      .filter((item) => item.code === 'PAYMENT_METHOD')
      .map((i) => i.value);

    const facility = filterList
      .filter((item) => item.code === 'FACILITY')
      .map(({ value }: { value: string }) => {
        const sliptvalue = value.split('_');
        sliptvalue.shift();
        return sliptvalue.length > 1
          ? sliptvalue.join('_').toLowerCase()
          : sliptvalue[0].toLowerCase();
      });

    const queryParams = {};

    code && Object.assign(queryParams, { area: code });
    code === 'map_pin' && Object.assign(queryParams, { lat, lng });
    keyword && Object.assign(queryParams, { keyword });

    // province && Object.assign(queryParams, { province });

    category &&
      Object.assign(queryParams, {
        // category: CategoryCodeNumber[category.value].join(','),
        category: category.value.split('_')[1].toLowerCase(),
      });
    payment.length > 0 &&
      Object.assign(queryParams, { payment: payment.join(',') });
    facility.length > 0 &&
      Object.assign(queryParams, {
        facility: facility.join(','),
      });
    storeImage && Object.assign(queryParams, { storeImage: !!storeImage });
    storeOpen && Object.assign(queryParams, { storeOpen: !!storeOpen });
    promotion && Object.assign(queryParams, { promotion: !!promotion });
    discount && Object.assign(queryParams, { discount: !!discount });
    Object.assign(queryParams, { storeFront: storeFront?.value === true });
    page && Object.assign(queryParams, { page });

    return queryParams;
  }

  getLocationObjectByCode(
    code: string,
    initLocation?: { lat: string; lng: string }
  ): ProvinceModel | null {
    if (!code) return null;
    let result: ProvinceModel | null = null;
    switch (code) {
      case 'near_me':
        (() => {
          const { coords = undefined } = this.geolocationPosition || {};
          result = {
            id: '0',
            code: 'near_me',
            province: 'ร้านใกล้ฉัน',
            lat: coords && coords.latitude ? coords.latitude.toString() : '',
            lng: coords && coords.longitude ? coords.longitude.toString() : '',
          };
        })();
        break;
      case 'map_pin':
        (() => {
          const location =
            initLocation && initLocation.lat && initLocation.lng
              ? initLocation
              : this.searchStore.getValue();
          result = {
            id: '0',
            code: 'map_pin',
            province: 'ตำแหน่งหมุด',
            lat: location.lat || '',
            lng: location.lng || '',
          };
        })();
        break;
      default:
        (() => {
          const provinceList = this.masterDataStore.getValue().provinceList;
          result = provinceList.find((item) => item.code === code) || null;
        })();
        break;
    }
    return result;
  }
}

// id: string;
// code: string;
// province: string;
// lat: string;
// lng: string;
