import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SearchState } from './search.state';

export function createInitialSearchState(): SearchState {
  return {
    id: '1',
    code: '010',
    province: 'กรุงเทพมหานคร',
    lat: '13.7563309',
    lng: '100.5017651',
    keyword: '',
    filterList: [
      {
        code: 'STATUS_STOREFRONT',
        value: true,
      },
    ],
    page: 1,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'search', resettable: true })
export class SearchStore extends Store<SearchState> {
  constructor() {
    super(createInitialSearchState());
  }
}
