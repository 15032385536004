import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';

export interface DialogConfig {
  title?: string;
  message: string;
}

@Injectable()
export class CommonDialogService {
  constructor(private modalService: NgbModal) {}
  alert(dialogConfig: DialogConfig): Observable<void> {
    const config: NgbModalOptions = {
      animation: true,
      backdrop: true,
      keyboard: true,
      centered: true,
    };
    const dialogRef = this.modalService.open(AlertDialogComponent, config);
    dialogRef.componentInstance.title = dialogConfig.title;
    dialogRef.componentInstance.message = dialogConfig.message;
    return dialogRef.closed;
  }
}
