export interface MasterDataItem {
  code: string;
  iconUrl?: string;
  name?: string;
  child?: MasterDataItem[];
  partner?: MasterDataItem[];
}

export interface MasterDataItemDTO {
  code: string;
  name?: string;
  startDateTime?: string;
  endDateTime?: string;
  group?: string;
  partner?: MasterDataItemDTO[];
  image?: string;
}

export interface Pagination {
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface CategoryMasterDataItem extends MasterDataItem {
  code: string;
  subcodeList: string[];
  categoryCode?: string[];
  subCategoryCode?: string[];
}

export interface PaymentMasterDataItem extends MasterDataItem {
  partner?: MasterDataItem[];
}

export interface MasterDataContent {
  category?: Array<MasterDataItemDTO>;
  facilies?: Array<MasterDataItemDTO>;
  paymentMethod?: Array<MasterDataItemDTO>;
  promotion?: Array<MasterDataItemDTO>;
  status?: Array<MasterDataItemDTO>;
  location?: {
    latitude?: number;
    longitude?: number;
    lat?: number;
    lon?: number;
  };
  expireDateTime?: string;
  advertises: Adverties[];
}

export interface Adverties {
  id: string;
  brand: string;
  logo: any[];
  title: string;
  description: string;
  image: string;
  url: string;
}

export interface MasterDataContent {
  category?: Array<MasterDataItemDTO>;
  facilies?: Array<MasterDataItemDTO>;
  paymentMethod?: Array<MasterDataItemDTO>;
  promotion?: Array<MasterDataItemDTO>;
  status?: Array<MasterDataItemDTO>;
  location?: {
    latitude?: number;
    longitude?: number;
    lat?: number;
    lon?: number;
  };
  expireDateTime?: string;
}

export const CategoryCodeNumber: { [key: string]: string[] } = {
  CAT_FOOD: ['10001', '10007', '50001'] as string[],
  CAT_HOTEL: ['10003', '10004', '50004'] as string[],
  CAT_TRAVEL: ['10006', '50005'] as string[],
  CAT_STORE: ['50002', '20001'] as string[],
  CAT_FASHION: ['50003'] as string[],
  CAT_BUETY: ['50006', '50008', '50009', '50016'] as string[],
  CAT_TRANSPORT: ['10005', '50007', '50014', '50018'] as string[],
  CAT_BLUEFLAG: ['10000'] as string[],
  CAT_GENERAL: [
    '50012',
    '50015',
    '50017',
    '50022',
    '50024',
    '50025',
  ] as string[],
  CAT_EACH: [
    '10002',
    '30001',
    '40001',
    '50010',
    '50011',
    '50013',
    '50019',
    '50020',
    '50021',
    '50023',
    '50026',
    '50027',
  ] as string[],
} as const;

export const CategorySubCodeValue: { [key: string]: string } = {
  10001: `ร้านอาหาร/เครื่องดื่ม`,
  10007: `ร้านอาหาร/เครื่องดื่ม - เฟส2`,
  50001: `อาหาร ของหวาน เครื่องดื่ม`,
  10003: `โรงแรม/ที่พัก/โฮมสเตย์`,
  10004: `โรงแรม/ที่พัก - เฟส2`,
  50004: `ที่พัก/โรงแรม`,
  10006: `บริษัททัวร์ - เฟส2`,
  50005: `สถานที่ท่องเที่ยว/แหล่งท่องเที่ยวเพื่อนันทนาการ`,
  50002: `ขายของใช้และอุปกรณ์/ซุปเปอร์มาร์เก็ต/ขายของชำ/มินิมาร์ท/ตลาด`,
  20001: `ร้านค้าทั่วไป`,
  50003: `แฟชั่น`,
  50006: `สุขภาพ/ความงาม`,
  50008: `สปา/นวด`,
  50009: `กีฬา`,
  50016: `กลุ่มบริการด้านสุขภาพและการแพทย์`,
  10005: `รถเช่า - เฟส2`,
  50007: `กิจการยานพาหนะ`,
  50014: `กลุ่มขนส่งสาธารณะ`,
  50018: `กลุ่มขนส่งทั่วไป`,
  10000: `ร้านค้าธงฟ้าถุงเงิน`,
  50012: `งานบริการอื่นๆ/เบ็ดเตล็ด`,
  50015: `กลุ่มงานรับเหมา/งานช่าง/ทำความสะอาด`,
  50017: `กลุ่มบริการทั่วไป`,
  10002: `ร้านค้าท้องถิ่น/ผลิตภัณฑ์ชุมชน/OTOP`,
  30001: `ร้านก๊าซ`,
  40001: `ร้านช้อป 10%`,
  50010: `ของฝาก/ของที่ระลึก`,
  50011: `ผลิตภัณฑ์ทางการเกษตร/สัตว์เลี้ยง/ต้นไม้/ดอกไม้`,
  50013: `กิจการ OTOP`,
  50019: `อัญมณีและเครื่องประดับ`,
  50020: `กิจการจำหน่ายสลากฯ`,
  50021: `สถานีบริการน้ำมันเชื้อเพลิง/ปั๊มน้ำมัน/EV`,
  50022: `การเงิน`,
  50023: `การศึกษา`,
  50024: `สาธารณูปโภค`,
  50025: `หน่วยงานรัฐบาล/องค์การมหาชน`,
  50026: `องค์กร/สมาคม`,
  50027: `หน่วยรับบริจาค`,
} as const;

export type CategoryCode =
  | 'CAT_FOOD'
  | 'CAT_HOTEL'
  | 'CAT_TRAVEL'
  | 'CAT_STORE'
  | 'CAT_FASHION'
  | 'CAT_BUETY'
  | 'CAT_TRANSPORT'
  | 'CAT_BLUEFLAG'
  | 'CAT_GENERAL'
  | 'CAT_EACH';

export type PaymentMethodCode =
  | 'near_me'
  | 'energy'
  | 'food_deliver'
  | 'loyalty_point'
  | 'atk'
  | 'half_each'
  | 'topup_card'
  | 'spend_to_get'
  | 'travel_tour'
  | 'travel_together'
  | 'welfare'
  | 'welfare_gas'
  | 'welfare_transit'
  | 'promptpay'
  | 'glo';

export type FacilityCode =
  | 'FAC_ALL'
  | 'FAC_VACCINATED'
  | 'FAC_WIFI'
  | 'FAC_PARK'
  | 'FAC_RESTROOM'
  | 'FAC_AIR_CONDITIONER'
  | 'FAC_WHEELCHAIR'
  | 'FAC_PET'
  | 'FAC_CHILDREN'
  | 'FAC_SMOKING_AREA'
  | 'FAC_BOOK_IN_ADVANCE'
  | 'FAC_OUTSIDE_SEAT'
  | 'FAC_INSIDE_SEAT'
  | 'FAC_WORKING_SPACE'
  | 'FAC_ALCOHOL'
  | 'FAC_LIVE_MUSIC'
  | 'FAC_FOOD'
  | 'FAC_AIRPORT_TRANSFER'
  | 'FAC_SWIMMING_POOL'
  | 'FAC_FITNESS'
  | 'FAC_SPA'
  | 'FAC_LAUNDRY'
  | 'FAC_VEHICLE_RENT'
  | 'FAC_MEETING_ROOM'
  | 'FAC_EV_CHARGER'
  | 'FAC_ENTRANCE_FEE';

export const FacilityGroupValue: { [key: string]: string[] } = {
  CAT_FOOD: [
    'FAC_VACCINATED',
    'FAC_WIFI',
    'FAC_PARK',
    'FAC_RESTROOM',
    'FAC_AIR_CONDITIONER',
    'FAC_WHEELCHAIR',
    'FAC_PET',
    'FAC_CHILDREN',
    'FAC_SMOKING_AREA',
    'FAC_BOOK_IN_ADVANCE',
    'FAC_OUTSIDE_SEAT',
    'FAC_INSIDE_SEAT',
    'FAC_WORKING_SPACE',
    'FAC_ALCOHOL',
    'FAC_LIVE_MUSIC',
    'FAC_MEETING_ROOM',
    'FAC_EV_CHARGER',
  ] as string[],
  CAT_HOTEL: [
    'FAC_VACCINATED',
    'FAC_WIFI',
    'FAC_PARK',
    'FAC_WHEELCHAIR',
    'FAC_PET',
    'FAC_CHILDREN',
    'FAC_FOOD',
    'FAC_AIRPORT_TRANSFER',
    'FAC_SWIMMING_POOL',
    'FAC_FITNESS',
    'FAC_SPA',
    'FAC_LAUNDRY',
    'FAC_VEHICLE_RENT',
    'FAC_MEETING_ROOM',
    'FAC_EV_CHARGER',
  ] as string[],
  CAT_ETC: [
    'FAC_VACCINATED',
    'FAC_WIFI',
    'FAC_PARK',
    'FAC_RESTROOM',
    'FAC_AIR_CONDITIONER',
    'FAC_WHEELCHAIR',
    'FAC_PET',
    'FAC_CHILDREN',
    'FAC_SMOKING_AREA',
    'FAC_MEETING_ROOM',
    'FAC_EV_CHARGER',
    'FAC_ENTRANCE_FEE',
  ] as string[],
} as const;
