import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MasterDataState } from './master-data.state';

export function createInitialMasterDataState(): MasterDataState {
  return {
    provinceList: [],
    paymentList: [],
    categoryList: [],
    facilieList: [],
    promotionList: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'masterdata' })
export class MasterDataStore extends Store<MasterDataState> {
  constructor() {
    super(createInitialMasterDataState());
  }
}
