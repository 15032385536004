import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PromotionComponent } from './promotion/promotion.component';
import { TranslateModule } from '@ngx-translate/core';
import { MarketComponent } from './market/market.component';
import { AdvertismentComponent } from './advertisment/advertisment.component';
import { ProductComponent } from './product/product.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RecommendItemCardComponent } from './recommend-item-card/recommend-item-card.component';
import { MerchantItemCardComponent } from './merchant-item-card/merchant-item-card.component';

@NgModule({
  declarations: [
    PromotionComponent,
    MarketComponent,
    AdvertismentComponent,
    ProductComponent,
    RecommendItemCardComponent,
    MerchantItemCardComponent,
  ],
  imports: [CommonModule, TranslateModule, NgxSkeletonLoaderModule],
  exports: [
    PromotionComponent,
    MarketComponent,
    AdvertismentComponent,
    ProductComponent,
    RecommendItemCardComponent,
    MerchantItemCardComponent,
  ],
})
export class CardModule {}
