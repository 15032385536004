import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'environment';
import { RecommendFilterResponse } from '../models/recommend-filter.model';

@Injectable({
  providedIn: 'root',
})
export class RecommendFilterService {
  constructor(private http: HttpClient) {}

  public get(): Observable<RecommendFilterResponse> {
    const url = `${environment.service.host}/recommend-filter`;
    return this.http
      .post(url, { headerReq: {} }, { responseType: 'text' })
      .pipe(map((_) => JSON.parse(_)));
  }
}
