import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { QueryParamState } from './query-param.state';
import { QueryParamStore } from './query-param.store';

@Injectable({ providedIn: 'root' })
export class QueryParamQuery extends Query<QueryParamState> {
  constructor(private queryParamStore: QueryParamStore) {
    super(queryParamStore);
  }
}
