import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from '../footers/footer/footer.component';

@NgModule({
  declarations: [VideoPlayerComponent],
  imports: [CommonModule, TranslateModule],
  exports: [VideoPlayerComponent],
})
export class VideoPlayerModule {}
