import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ProvinceModel } from '../../models/province.model';

export interface ProvinceListState {
  provinceList: ProvinceModel[] | [];
}

export function createInitialProvinceState(): ProvinceListState {
  return {
    provinceList: [],
  };
}

@StoreConfig({ name: 'provinceList' })
@Injectable({ providedIn: 'root' })
export class ProvinceListStore extends Store<ProvinceListState> {
  constructor() {
    super(createInitialProvinceState());
  }
}
