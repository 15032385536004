import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { RecommendNotFoundComponent } from './recommend-not-found/recommend-not-found.component';

@NgModule({
  declarations: [NotFoundComponent, RecommendNotFoundComponent],
  imports: [CommonModule, TranslateModule],
  exports: [NotFoundComponent, RecommendNotFoundComponent],
})
export class NotFoundModule {}
