import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { MasterDataStore } from '../stored';
import { SearchStore } from '../stored/search/search.store';
import { MasterDataService } from './master-data.service';

export interface latlng {
  lat: number;
  lng: number;
  change: boolean;
}

export interface prevLatlng {
  lat: number;
  lng: number;
  province: string;
  change: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LatLngService {
  private _currentUserLocation: BehaviorSubject<
    GeolocationPosition | undefined
  > = new BehaviorSubject<GeolocationPosition | undefined>(undefined);

  get currentUserLocation$(): Observable<GeolocationPosition | undefined> {
    return this._currentUserLocation.asObservable();
  }

  get currentUserLocation(): GeolocationPosition | undefined {
    return this._currentUserLocation.getValue();
  }

  private subject = new BehaviorSubject<latlng>({
    lat: 0,
    lng: 0,
    change: false,
  });
  observable = this.subject.asObservable();

  private previousSubject = new BehaviorSubject<prevLatlng>({
    lat: 0,
    lng: 0,
    province: 'กรุงเทพมหานคร',
    change: false,
  });
  previousObservable = this.previousSubject.asObservable();

  constructor(
    private masterDataService: MasterDataService,
    private masterDataStore: MasterDataStore,
    private searchStore: SearchStore
  ) {
    // this.initialLocation();
  }

  getGeoloLocation(): Observable<GeolocationPosition | undefined> {
    // console.log('Get GeoLocation');

    return new Observable((observer) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          observer.next(position);
          this._currentUserLocation.next(position);
          this.searchStore.update(() => {
            return {
              lat: position.coords.latitude.toString(),
              lng: position.coords.longitude.toString(),
              province: 'ร้านใกล้ฉัน',
              code: 'near_me',
              id: '0',
            };
          });
          observer.complete();
        },
        () => {
          observer.next(undefined);
          this._currentUserLocation.next(undefined);
          observer.complete();
        }
      );
    });
  }

  // initialLocation(): void {
  //   this.masterDataService.getCurrentUserLocation().subscribe({
  //     next: (location) => {
  //       if (location) {
  //         const { latitude: lat, longitude: lng } = location.coords;
  //         console.log({ latitude: lat, longitude: lng });
  //       }
  //     },
  //   });
  // }

  set(value: { lat: number; lng: number; change: boolean }) {
    this.subject.next(value);
  }

  get(): { lat: number; lng: number; change: boolean } {
    return this.subject.getValue();
  }

  setPrevious(value: {
    lat: number;
    lng: number;
    province: string;
    change: boolean;
  }) {
    this.previousSubject.next(value);
  }

  getPrevious(): {
    lat: number;
    lng: number;
    province: string;
    change: boolean;
  } {
    return this.previousSubject.getValue();
  }
}
