import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environment';
import { map, Observable, tap } from 'rxjs';
import {
  SearchRequest,
  SearchResponse,
  SearchResultResponse,
} from '../models/search.model';

const httpOptions = {
  headers: new HttpHeaders({
    reqID: '',
    reqDtm: '',
    'content-type': 'application/json;charset=UTF-8',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private http: HttpClient) {}

  public webSearch(payload: SearchRequest): Observable<SearchResponse> {
    const url = `${environment.service.host}/search`;
    return this.http
      .post(url, payload, { responseType: 'text' })
      .pipe(map((_) => JSON.parse(_)));
  }

  public searchData(payload: SearchRequest): Observable<SearchResultResponse> {
    const url = `${environment.service.host}/search`;
    return this.http
      .post(url, payload, { responseType: 'text' })
      .pipe(map((_) => JSON.parse(_)));
  }
}
