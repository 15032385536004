import { pki } from 'node-forge';
import { Injectable } from '@angular/core';
import { environment } from 'environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  async encrypt(body: object): Promise<{
    param1: string;
    param2: string;
    key: CryptoKey;
  }> {
    const objetToString = JSON.stringify(body);
    const uint8ArrayBody = new TextEncoder().encode(objetToString);

    // Generate a random 256-bit key
    const stringKey = this.generateRandomAlphanumeric(32);
    const key = await crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(stringKey),
      { name: 'AES-GCM' },
      true,
      ['encrypt', 'decrypt']
    );

    //rabdom string 12 -> Uint8Array
    const iv = crypto.getRandomValues(new Uint8Array(12));

    const encryptedData = await crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      key,
      uint8ArrayBody
    );
    return {
      param1: this.uint8ArrayToBase64(
        this.concatenateUint8Arrays(iv, new Uint8Array(encryptedData))
      ),
      param2: btoa(this.rsaEncrypt(stringKey, environment.security.publicKey)),
      key,
    };
  }

  async decrypt(encryptedResponse: string, key: CryptoKey): Promise<any> {
    console.log('decrypt@@@@');
    const decodedResponse = this.base64ToUint8Array(encryptedResponse);

    const iv = decodedResponse.slice(0, 12);
    const encryptedData = decodedResponse.slice(12);

    const decryptedData = await crypto.subtle.decrypt(
      {
        name: 'AES-GCM',
        iv: iv,
      },
      key,
      encryptedData
    );

    console.log('decode', JSON.parse(new TextDecoder().decode(decryptedData)));
    return new TextDecoder().decode(decryptedData);
  }

  private uint8ArrayToBase64(uint8Array: Uint8Array) {
    let binaryString = '';
    for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binaryString);
  }

  private concatenateUint8Arrays(array1: Uint8Array, array2: Uint8Array) {
    // Create a new Uint8Array with length equal to the sum of the lengths of the two arrays
    let concatenatedArray = new Uint8Array(array1.length + array2.length);

    // Set the values of the new array
    concatenatedArray.set(array1, 0);
    concatenatedArray.set(array2, array1.length);

    return concatenatedArray;
  }

  private generateRandomAlphanumeric(length: number) {
    const charset =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const result = [];
    const values = new Uint8Array(length);
    crypto.getRandomValues(values);
    for (let i = 0; i < length; i++) {
      result.push(charset[values[i] % charset.length]);
    }
    return result.join('');
  }

  private base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  private rsaEncrypt(value: string, key: string): string {
    const rsa = pki.publicKeyFromPem(key);
    return rsa.encrypt(value);
  }
}
